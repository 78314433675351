import React, { Component } from 'react';
import {
  Form, FormGroup, Label, Button, Input, Alert, Col
} from 'reactstrap';
import Select from 'react-select';
import { FlagIcon } from 'react-flag-kit';
import './style.css';
import { get_marketplace_mapping } from '../../../../helpers/utility';
import "react-awesome-popover/build/index.css";

const labelFlag = (data) => (
	<span>{data.label} <FlagIcon code={data.flag} /></span>
);

class AmazonAuthorization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marketplace: null,
      amazon_seller_id: "",
	    amazon_seller_id_ok: false,
    }
  }

  check_seller_id = (seller_id) => {
    var amazon_seller_id_ok = /^[A-Z0-9]*$/.test(seller_id);
    if(!seller_id){ amazon_seller_id_ok = false; }
		this.setState({ amazon_seller_id_ok: amazon_seller_id_ok });
	}

	UNSAFE_componentWillReceiveProps(np){
		this.setState({
			marketplace: get_marketplace_mapping(false, np.amazon_marketplace),
			amazon_seller_id: np.amazon_seller_id,
		});
		this.check_seller_id(np.amazon_seller_id);
	}

  marketplaceChanged = (selected) => {
    this.setState({ marketplace: selected });
  }

	handleInput = (e, source) => {
		this.setState({
	      [source]: e.target.value
		});
		if(source === "amazon_seller_id"){
			this.check_seller_id(e.target.value);
		}
	}

	linkAccount = () => {
		let ud = this.props.userData;
		ud.sellerId = this.state.amazon_seller_id;
		ud.marketplaceId = this.state.marketplace.marketplaceID;
		this.props.updateUserData(ud);
    /*
		this.props.updateUserDataSettings({
      seller_id: this.state.amazon_seller_id,
      marketplace_id: this.state.marketplace.marketplaceID,
    });
    */
    this.props.userSPAPIAuthStart({
      source: "onboarding",
      marketplace: this.state.marketplace.marketplaceID,
      seller: this.state.amazon_seller_id,
    });
	}

  render() {
    return(
      <Form className="form-link-account">
        <FormGroup>
          <Label>Pick your marketplace carefully</Label>
          <div style={
            !this.state.marketplace ?
            {
                borderColor: "red",
                borderStyle: "solid",
                borderWidth: "1px"
            } : null
          }>
          <Col>
            <Select
              options={ get_marketplace_mapping(true) }
              optionRenderer={labelFlag}
              valueRenderer={labelFlag}
              value={this.state.marketplace}
              onChange={this.marketplaceChanged}
              clearable={false}
            />
          </Col>
          <Col md={{ size: 4, offset: 4}}>
            <span id="arrow-credentials">
            <svg width="49" height="138" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.879 33.505a78.12 78.12 0 011.372-1.515c-.54.143-1.082.29-1.62.444.093.3.17.598.223.9l.025.171zM1.515 85c-2.302-16.565.408-32.417 6.128-45.911 3.39-7.998 7.885-15.275 13.294-21.613a3.232 3.232 0 01-.749-.315c-.115-.015-.394-.1-.513-.099-.323 0-.643.03-.968.03-1.243.003-2.658-1.19-3.583-2.16-1.432-1.506-2.708-3.139-3.633-5.006-.281-.566-1.448-3.026-.546-3.355C20.482 3.13 31.03 2.364 41.184.525c1.361-.249 3.79 3.008 4.52 4.014.922 1.27 2.839 4.006 2.42 5.545-3.254 11.928-5.28 25.495-2.325 39.227.564 2.616-2.406.473-3.25-.354-1.915-1.876-3.852-4.57-4.41-7.155-1.7-7.892-1.843-15.765-1.06-23.326C18.057 34.574 5.753 60.856 9.021 90.538c.81 7.34 2.633 14.46 5.558 21.681.034.08.087.195.15.353.202.468.414.932.621 1.4.421.939.868 1.87 1.323 2.796.395.805.818 1.602 1.24 2.397-.034-.073.471.845.51.914.26.463.529.916.798 1.372a93.206 93.206 0 001.685 2.722c.05.08.317.48.476.722.241.36.487.71.732 1.068a98.78 98.78 0 001.941 2.662c.23.304.463.605.697.91.016.013.632.792.704.88 1.334 1.634 3.816 4.761 3.365 6.867-.439 2.042-3.76-1.341-4.383-2.106-12.13-14.885-20.435-32.252-22.924-50.177z" fill="#E2E5E0" fillRule="evenodd"/>
            </svg>
            </span>
            <h3 className="h6">AccelerList Developer Credentials</h3>
            <ul className="list-unstyled" style={{ fontSize: "1.2rem" }}>
              <li><FlagIcon code="US" /> 5445-7997-8640</li>
              <li><FlagIcon code="GB" /> 1136-4433-4378</li>
              <li><FlagIcon code="CA" /> 5445-7997-8640</li>
              <li><FlagIcon code="DE" /> 1992-9749-6484</li>
            </ul>
          </Col>
        </div>
        </FormGroup>
        <FormGroup id="seller-id-input">
          <Label>Link your Amazon {this.state.marketplace !== null && this.state.marketplace.value} account to AccelerList</Label>
          <Label className="label-secondary">Seller ID (Merchant Number)<span className="text-danger">*</span></Label>
          <Input
            value={this.state.amazon_seller_id}
            onChange={e => this.handleInput(e, "amazon_seller_id")}
            style={this.state.amazon_seller_id_ok ? {} : { borderColor: "red" }}
          />
          <div className="popover show bs-popover-right popover-help-bubble">
            <div className="popover-body">
              Need help finding your Seller ID?
              Feel free to chat us by clicking picture on
              the bottom right of your screen.
            </div>
          </div>
        </FormGroup>
        <Button
          type="button"
          color="success"
          block
          onClick={this.linkAccount}
          disabled={
            !this.state.amazon_seller_id_ok
              || !this.state.marketplace
          }
        >LINK ACCOUNT</Button>
        <FormGroup className="mt-2">
          <Alert color="warning">
            Upon clicking the “Link Account” button you will be transferred to Amazon Seller Central, accept the Terms and Conditions. Then confirm and you should get back on this screen.
          </Alert>
        </FormGroup>
      </Form>
    );
  }
}

export default AmazonAuthorization;
