import React from "react";
import { connect } from "react-redux";
import addressAction from "../../../redux/address/actions";
import { Card, CardBody, CardTitle, Col, Button, FormGroup, Label, Input, InputGroup, InputGroupAddon, ButtonGroup } from "reactstrap";
import RenderForm from "../../forms/renderForm";
import formSpecs from "./formSpecs";
import "./style.css";
import EditAddressModal from "../../../shared/components/EditAddressModal";
import { FlagIcon } from "react-flag-kit";
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import { Alert } from "reactstrap";
import settingsActions from "../../../redux/settings/actions";

const {
	updateUserDataSettings,
} = settingsActions

const { fetchAdressList, createAddress, deleteAddress, updateAddress } = addressAction;

const AddedAddresses = ({ addressStore, deletingId, removeAddress, addressDisplayFormat, fetchAddress, editAddress }) => (
  <React.Fragment>
    {addressStore && addressStore.length > 0 ? (
      addressStore.map(address => {
        return (
          <div className="row added-address" key={address.id}>
            <Col sm="9">
              {addressDisplayFormat(address).map((addressLine, idx) => {
                  return <p className="mb-0" key={address.id+"-"+idx}>{addressLine}</p>
              })}
            </Col>
            {deletingId !== address.id && (
              <Col
                sm="3"
                className="icon-btn-container"
              >
                <div
                  className="icon-btn ml-2"
                  onClick={() => { removeAddress(address.id);}}
                >
                  <span className="icon icon-trash bg-danger"></span>
                </div>
                <div
                  className="icon-btn"
                  onClick={() => { editAddress(address.id);}}
                >
                  <span className="icon icon-edit"></span>
                </div>
              </Col>
            )}
          </div>
        );
      })
    ) : (
      <div>No added address</div>
    )}
  </React.Fragment>
);

const labelFlag = (data) => (
	<span><FlagIcon code={data.value} /> ({data.label})</span>
);

class Address extends React.Component {
  constructor(props) {
    let country = "US";
    if (props.internationalization_config && props.internationalization_config.ship_to_country_code) {
      country = props.internationalization_config.ship_to_country_code;
    }

    super(props);
    this.state = {
      country: country,
      editAddressId: null,
      address_edit_data: null,
      selected_country: null,
      phone: null,
    }
  }

  componentDidMount() {
    this.props.fetchAdressList();
  }
  removeAddress = id => {
    this.props.deleteAddress(id);
  };
  fetchAddress = id => {
    console.log(this.props.fetchAdressList())
  };
  submitFormValues = values => {
    values.countryCode = this.state.country;
    this.props.createAddress(values, formSpecs[this.state.country].form.id);
  };

  updateAddressEditData = (key, value) => {
    var data = {...this.state.address_edit_data};
    data[key] = value;
    this.setState({ address_edit_data: data });
  }

  updateAddress = () => {
    this.props.updateAddress(this.state.address_edit_data);
    this.setState({
      editAddressId: null,
      address_edit_data: null,
    });
  }

  onCountryChange = (value) => {
    this.setState({
      selected_country: value,
    })
  }

  updatePhone = () => {
    const { phone, selected_country } = this.state;
    if(phone && phone.length === 10 && selected_country?.label){
      const phone_number = `${selected_country?.label}${phone}`;
	  	this.props.updateUserDataSettings({
		  	phone: phone_number
  		});
    }
  }

  render() {
    const { userData } = this.props;
    let countryButtons = ["US", "CA", "GB", "DE"].map((country, key) => {
      return (
        <Button
          size="sm"
          active={this.state.country === country}
          onClick={() => this.setState({country})}
					key={key}
        ><FlagIcon code={country} /> Display <span title={country}>{country}</span> address form</Button>
      )
    })

    let phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    if(this.state.selected_country !== null && this.state.selected_country.value === "GB") {
      phoneMask = [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    }

    return (
      <React.Fragment>
          <Card>
            <CardBody>
			  <CardTitle>Address Settings</CardTitle>
              <div className="row">
                <Col sm={8}>
                  <p>
                  Click on your country address format and enter your SHIP FROM address.
                  </p>
                  <ButtonGroup>
                  {countryButtons}
                  </ButtonGroup>
                  <hr />
                  <RenderForm
                    formSpecs={formSpecs[this.state.country]}
                    disableForm={this.props.savingAddress}
                    submitFormValues={this.submitFormValues.bind(this)}
                  />
                </Col>
                <Col sm={4}>
                  <div className="prev-added-adress">
                    <p className="heading">PREVIOUSLY ADDED ADDRESSES</p>
                    {this.props.loadingList ? (
                      <p>Loading...</p>
                    ) : (
                      <AddedAddresses
                        deletingId={this.props.deletingId}
                        addressStore={this.props.addressList}
                        removeAddress={this.removeAddress}
                        addressDisplayFormat={formSpecs[this.state.country].renderAddressData}
                        editAddress={
                          (id) => {
                            this.setState({
                              editAddressId: id,
                              address_edit_data: this.props.addressList.find(item => item.id === id),
                            });
                          }
                        }
                      />
                    )}
                  </div>
                  <div className="mt-5">
                    <h5 className="card-title mb-2">Phone Number</h5>
                    <FormGroup>
                      <Label className="col-form-label">Update phone number {userData?.phone ? `(${userData?.phone})` : null}</Label>
                      <InputGroup>
                        <div
                          className="input-group-prepend"
                          style={!this.state.selected_country ? { border: "1px solid red", marginRight: 0 } : {}}
                          >
                          <Select
                            optionRenderer={labelFlag}
                            valueRenderer={labelFlag}
                            style={{ width: '7.3rem', height: '2.7rem' }}
                            clearable={false}
                            options={[
                              {value: 'US', label: '+1'},
                              {value: 'CA', label: '+1'},
                              {value: 'GB', label: '+44'}
                            ]}
                            value={this.state.selected_country}
                            onChange={this.onCountryChange}
                          />
                        </div>
                        <MaskedInput
                          mask={phoneMask}
                          onChange={e =>
                            this.setState({
                              phone: e.target.value.replace(/\D+/g, ''),
                            })
                          }
                          className="form-control"
                          showMask={true}
                          keepCharPositions={true}
                          value={this.state.phone}
                        />
                        <InputGroupAddon addonType="append">
                        <Button
                          color="success"
                          onClick={() => this.updatePhone()}
                          disabled={
                            !(this.state.phone && this.state.phone.length === 10)
                              || !this.state.selected_country?.label
                              || this.props.isUserSettingsUpdating
                          }
                        >Update</Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </Col>
              </div>
            </CardBody>
          </Card>
          {this.state.editAddressId &&
            <EditAddressModal
              isOpen={this.state.editAddressId !== null}
              close={
                () => this.setState({
                  editAddressId: null,
                  address_edit_data: null,
                })
              }
              country={this.state.country}
              data={this.state.address_edit_data || {}}
              updateAddressEditData={this.updateAddressEditData}
              updateAddress={this.updateAddress}
            />
          }
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    ...state.Address.toJS(),
    userData: state.Auth.get("userData"),
    internationalization_config: state.Auth.get("internationalization_config"),
    userData: state.Auth.get("userData"),
    isUserSettingsUpdating: state.Settings.get("isUserSettingsUpdating"),
  }),
  {
    fetchAdressList,
    createAddress,
    deleteAddress,
    updateAddress,
  	updateUserDataSettings,
  }
)(Address);
