import React, { Component } from "react";
import {
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	Form,
	FormGroup,
	Label,
	Input,
	Button,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Badge,
	FormText
} from "reactstrap";
import { connect } from "react-redux";

import classnames from 'classnames';
import { func, object } from "prop-types";
import Address from "../address";
import ListingDefaults from "./Listing_Defaults";
import PrinterDefaultsQz from "./PrintSettings";
import UpdateBilling from "./UpdateBilling";
import UpdatePasswordForm from "./UpdatePasswordForm";
import AffiliateForm from "./AffiliateForm";
import SupplierList from "./SupplierList";
import ScoutList from "./ScoutList";
import SweetAlert from "sweetalert2-react";
import settingsActions from "../../../redux/settings/actions";
import authActions from "../../../redux/auth/actions";
import appActions from "../../../redux/app/actions";
import SKUSettings from "./SKU_Settings"
import EbaySKUSettings from "./EbaySKUSettings"
import EbaySettings from "./EbayListings";
import ebayActions from '../../../redux/ebay/actions';
import EmployeeAccounts from "./EmployeeAccounts";
import { checkIfEmployee, get_marketplace_mapping } from "../../../helpers/utility";
import Select from "react-select";
import { FlagIcon } from "react-flag-kit";
import SKUSettingsSaved from "./SKU_Settings_Saved";
import { momentDateToISOFormatConversioniWithFormat } from '../../../helpers/utility';
import moment from "moment";
import queryString from "query-string";
import Listing_Alerts from "./Listing_Alerts";
import { FaCheck } from "react-icons/lib/fa";

const {
  openMwsAuthorizeModal,
  getUser,
  userSPAPIAuthStart,
  updateUserData,
  userSPAPIAuthLWA,
} = authActions;
const { closeSettingsAlert } = settingsActions;
const { ebayUserAuth, ebayUserUpdateSettings } = ebayActions;

const {
	fetchListingDefaults,
	saveListingDefaults,
	updateUserDataSettings,
	updateUserSettings
} = settingsActions
const {
  userError,
  userPageviewCapture,
} = appActions;

const ViewHeader = () => (
	<header className="view-header">
		<h1 className="page-title">Settings</h1>
	</header>
);

const ViewContent = ({ children }) => (
	<div className="view-content view-components">
		<Card>
			<CardBody>{children}</CardBody>
		</Card>
	</div>
);

const labelFlag = (data) => (
	<span><FlagIcon code={data.flag} /> {data.marketplaceID}</span>
);

class Settings extends Component {

	constructor(props) {
		super(props);

		this.toggleTabs = this.toggleTabs.bind(this);

		this.state = {
			activeTab: '1',
			activeChildTab: '',
			businessName: "",
			sellerId: "",
			marketplaceId: "",
			authToken: "",
			userAuthExecuted: false,
			ebay_paypal_email: "",
			sales_rank_threshold: "",
			saveMessageFlashing: false,
			activeListingChild: '4a'
		};
  }

	saveNewDefaults = () => {
		if (!this.state.sales_rank_threshold || this.state.sales_rank_threshold <= 0) {
			return;
		}
		this.setState({	saveMessageFlashing: true });
		let defaults = {};
		defaults['sales_rank_threshold'] = this.state.sales_rank_threshold;
		this.props.saveListingDefaults(defaults);
		this.flashSaveMessage();
	}

	UNSAFE_componentWillReceiveProps(newProps){
		if(newProps.userData){
			if(newProps.userData.businessName){
				this.setState({businessName: newProps.userData.businessName});
			}
			if(newProps.userData.sellerId){
				this.setState({sellerId: newProps.userData.sellerId});
			}
			if(newProps.userData.marketplaceId){
				this.setState({marketplaceId: newProps.userData.marketplaceId});
			}
			if(newProps.userData.authToken){
				this.setState({authToken: newProps.userData.authToken});
			}
			if(newProps.userData.settings){
				this.setState({ebay_paypal_email: newProps.userData.settings.ebay_paypal_email});
			}
		}
		if(newProps.listingDefaults){
			this.setState({sales_rank_threshold: newProps.listingDefaults.sales_rank_threshold});
		}
	}

	updatePaypalEmail = (val) => {
		this.setState({ebay_paypal_email: val});
	}

	savePaypalEmail = () => {
	  const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["ebay_paypal_email"] = this.state.ebay_paypal_email;
			this.props.updateUserData(ud);
			let data = {ebay_paypal_email: ud.settings["ebay_paypal_email"]};
			this.props.updateUserSettings(data);
		}
	}

	toggleTabs(tab) {
		if (this.state.activeTab !== tab) {
				this.setState({
				activeTab: tab
				});
		}
	}

	toggleChildTabs(tab) {
		if (this.state.activeChildTab !== tab) {
				this.setState({
					activeChildTab: tab
				});
		}
	}

	handleUpdateBusinessName(){
		const { businessName } = this.state;
		this.props.updateUserDataSettings({
			business_name: businessName
		});
	}

	handleUpdateSellerId(){
		const { sellerId } = this.state;
		this.props.updateUserDataSettings({
			seller_id: sellerId
		});
	}

	handleUpdateMarketplaceId = (marketplace) => {
		this.props.updateUserDataSettings({
			marketplace_id: marketplace.marketplaceID
		});
	}

  handleUpdateUnifiedAccount = () => {
	  const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["is_north_america_unified_account"] = !ud.settings["is_north_america_unified_account"];
			this.props.updateUserData(ud);
			let data = {is_north_america_unified_account: ud.settings["is_north_america_unified_account"]};
			this.props.updateUserSettings(data);
		}
	}

  static propTypes = {
    closeSettingsAlert: func.isRequired,
    openMwsAuthorizeModal: func.isRequired,
    currentAlert: object
  };

	componentDidMount() {
    this.props.userPageviewCapture({url: window.location.href, al_page: "Settings"})
    //handle AMAZON SPAPI AUTH
    const parsed_query = queryString.parse(this.props.location?.search);
    if(
      parsed_query?.selling_partner_id
      && parsed_query?.spapi_oauth_code
      && parsed_query?.state
    ){
      const data = {
        selling_partner_id: parsed_query?.selling_partner_id,
        spapi_oauth_code: parsed_query?.spapi_oauth_code,
        state: parsed_query?.state,
      }
      this.props.userSPAPIAuthLWA(data);
    }

		if (this.props.location.state && this.props.location.state.screenPosition) {
			window.location.href = `#${this.props.location.state.screenPosition}`;
		}
		if(this.props.userData){
			this.setState({businessName: this.props.userData.businessName});
		}

		const { location } = this.props;
		const urlParams = new URLSearchParams(location.search);
		const tab = urlParams.get('tab');
		if(tab){
			this.setState({ activeTab: tab });
		}
	}

	executeAuth = () => {
		this.setState({ userAuthExecuted: true });
		this.props.ebayUserAuth();
	}

  spapiOAuth = () => {
    this.props.userSPAPIAuthStart();
  }

  flashSaveMessage(kill=false) {
	//flash message on save
	if(kill){
		this.setState({	saveMessageFlashing: false });
		return;
	}
	setTimeout(() => {
	  this.flashSaveMessage(true);
	}, 2000);
}

  render() {
		const {
			closeSettingsAlert,
			currentAlert,
			openMwsAuthorizeModal,
			mwsAuthValues,
			listingDefaults,
			userError,
			isUserSettingsUpdating,
		} = this.props;

	  const { businessName, sellerId, marketplaceId, authToken } = this.state;
		return (
			<div className="view settings">
				<ViewHeader />
				<ViewContent>

				<div style={{width: "100%"}}>
					<Nav pills>
						<NavItem>
						<NavLink
							className={classnames({ active: this.state.activeTab === '1' })}
							style={{ cursor: "pointer" }}
							onClick={() => { this.toggleTabs('1'); }}
						>
							Account Settings
						</NavLink>
						</NavItem>
						{!checkIfEmployee(this.props.userData) ?
						<NavItem>
						<NavLink
							className={classnames({ active: this.state.activeTab === '2' })}
							style={{ cursor: "pointer" }}
							onClick={() => { this.toggleTabs('2'); }}
						>
							Address & Phone Settings
						</NavLink>
						</NavItem>
						: null}
						<NavItem>
						<NavLink
							className={classnames({ active: this.state.activeTab === '3' })}
							style={{ cursor: "pointer" }}
							onClick={() => { this.toggleTabs('3'); }}
						>
							Printer Settings
						</NavLink>
						</NavItem>
						<NavItem>
						<NavLink
							className={classnames({ active: this.state.activeTab === '4' })}
							style={{ cursor: "pointer" }}
							onClick={() => { this.toggleTabs('4'); }}
						>
							Listing Settings
						</NavLink>
						</NavItem>
						<NavItem>
						<NavLink
							className={classnames({ active: this.state.activeTab === '5' })}
							style={{ cursor: "pointer" }}
							onClick={() => {
								this.toggleTabs('5');
								this.toggleChildTabs('5a');
							}}
						>
							MSKU Settings
						</NavLink>
						</NavItem>
						{!checkIfEmployee(this.props.userData) ?
						<React.Fragment>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '6' })}
									style={{ cursor: "pointer" }}
									onClick={() => { this.toggleTabs('6'); }}
								>
									Supplier/Scout Settings
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '7' })}
									style={{ cursor: "pointer" }}
									onClick={() => { this.toggleTabs('7'); }}
								>
									Update Billing
								</NavLink>
							</NavItem>
						</React.Fragment>
						: null}
						</Nav>
					<Nav pills className="mt-2">
						{!checkIfEmployee(this.props.userData) ?
						<React.Fragment>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '8' })}
									style={{ cursor: "pointer" }}
									onClick={() => { this.toggleTabs('8'); }}
								>
									Profit Reprice&trade; Settings <Badge color="warning" pill>SOON</Badge>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '9' })}
									style={{ cursor: "pointer" }}
									onClick={() => { this.toggleTabs('9'); }}
								>
									Employee Accounts <Badge color="danger" pill>NEW</Badge>
								</NavLink>
							</NavItem>
						</React.Fragment>
						: null}
						{!checkIfEmployee(this.props.userData) ?
						<NavItem>
							<NavLink
								className={classnames({ active: this.state.activeTab === '10' })}
								style={{ cursor: "pointer" }}
								onClick={() => { this.toggleTabs('10'); }}
								disabled={
									!this.props.userData
										|| !this.props.userData.is_ebay_enabled
										|| !this.props.userData.ebay_auth}
							>
								eBay Settings <Badge color="danger" pill>NEW</Badge>
							</NavLink>
						</NavItem>
            : null }
						</Nav>
					<TabContent activeTab={this.state.activeTab}>
						<TabPane tabId="1">
							<Card>
								<CardBody>
									<CardTitle>Account Settings</CardTitle>
									<Row>
										<Col>
											<Card className="mb-4">
												<CardBody>
													<h6 className="card-title text-uppercase">
													Your Amazon Authorization Settings
													</h6>
													<Form>
														<FormGroup row>
															<Label for="userName" sm={3}>
															Username:
															</Label>
															<Col sm={9}>
															<Input
																value={
																this.props.userData !== undefined
																	? this.props.userData.userName
																	: ""
																}
																type="text"
																name="username"
																id="userName"
																disabled
															/>
															</Col>
														</FormGroup>
														<FormGroup row>
															<Label for="businessName" sm={3}>
															Business Name:
															</Label>
															<Col sm={7}>
															<Input
																value={ businessName ? businessName : "" }
																type="text"
																name="businessName"
																id="businessName"
																disabled={isUserSettingsUpdating || checkIfEmployee(this.props.userData)}
																onChange={(e) => {
																this.setState({
																	businessName: e.target.value
																})
																}}
															/>
															</Col>
															<Col sm={2}>
															<Button
																color="success"
																className="mb-2 float-right"
																disabled={isUserSettingsUpdating || checkIfEmployee(this.props.userData)}
																onClick={this.handleUpdateBusinessName.bind(this)}
															>Update</Button>
															</Col>
														</FormGroup>
														<FormGroup row>
															<Label for="email" sm={3}>
															Email:
															</Label>
															<Col sm={9}>
															<Input
																value={
																this.props.userData !== undefined
																	? this.props.userData.email
																	: ""
																}
																type="text"
																name="email"
																id="email"
																disabled
															/>
															</Col>
														</FormGroup>
														<FormGroup row>
															<Label for="phone" sm={3}>
															Phone:
															</Label>
															<Col sm={9}>
															<Input
																value={
																this.props.userData && this.props.userData.phone
																	? this.props.userData.phone
																	: ""
																}
																type="text"
																name="phone"
																id="phone"
																disabled
															/>
															</Col>
														</FormGroup>
														{!checkIfEmployee(this.props.userData) ?
														<React.Fragment>
														<FormGroup row>
															<Label for="sellerID" sm={3}>
															Seller ID:
															</Label>
															<Col sm={7}>
															<Input
																value={ sellerId }
																type="text"
																name="sellerID"
																id="sellerID"
																disabled={isUserSettingsUpdating}
																onChange={(e) => {
																this.setState({
																	sellerId: e.target.value
																})
																}}
															/>
															</Col>
															<Col sm={2}>
															<Button
																color="success"
																className="mb-2 float-right"
																disabled={isUserSettingsUpdating}
																onClick={this.handleUpdateSellerId.bind(this)}
															>Update</Button>
															</Col>
														</FormGroup>
                            {/*
														<FormGroup row>
															<Label for="mwsToken" sm={3}>
															MWS Auth.Token:
															</Label>
															<Col sm={7}>
															<Input
																value={ authToken }
																type="text"
																name="token"
																id="mwsToken"
																disabled={isUserSettingsUpdating}
																onChange={(e) => {
																this.setState({
																	authToken: e.target.value
																})
																}}
															/>
															</Col>
															<Col sm={2}>
															<Button
																color="primary"
																className="mb-2 float-right"
																disabled={isUserSettingsUpdating}
																onClick={this.handleUpdateAuthToken.bind(this)}
															>Update</Button>
															</Col>
                            </FormGroup>
                            */}
														<FormGroup row>
															<Label for="marketId" sm={3}>
															Marketplace ID:
															</Label>
															<Col sm={7}>
															<Select
																options={get_marketplace_mapping(true)}
																optionRenderer={labelFlag}
																valueRenderer={labelFlag}
																value={get_marketplace_mapping(false, marketplaceId)}
															  onChange={(e) => this.handleUpdateMarketplaceId(e)}
																clearable={false}
																disabled={isUserSettingsUpdating}
															/>
															</Col>
															<Col sm={2}>
															<Button
																color="success"
																className="mb-2 float-right"
																disabled={isUserSettingsUpdating}
																onClick={this.handleUpdateMarketplaceId.bind(this)}
															>Update</Button>
															</Col>
														</FormGroup>
                            {this.props.userData?.role === "admin" &&
														<FormGroup row>
															<Label for="spapi_enabled" sm={3}>
															SP-API enabled:
															</Label>
															<Col sm={7}>
                                {this.props.userData?.sp_api_token ? "YES" : "NO"}
															</Col>
															<Col sm={2}>
															</Col>
                            </FormGroup>
                            }
														</React.Fragment>
														: null}
													</Form>
                          {/*
													{!checkIfEmployee(this.props.userData) ?
													<React.Fragment>
													<Row>
													<Col xs="6">
														<Button
														onClick={openMwsAuthorizeModal}
														color="success"
														>
															{ mwsAuthValues.authToken ? "Re-Authorize" : "Authorize" }
														</Button>
													</Col>
                          */}
													{!checkIfEmployee(this.props.userData) ?
													<React.Fragment>
													<Row>
													<Col xs="6">
														<Button
                              onClick={() => {this.spapiOAuth()}}
  														color="success"
                              disabled={this.props.user_spapi_auth_start_loading}
														>
															{ this.props.userData?.sp_api_token ? "Re-Authorize" : "Authorize" }
                            </Button>
													</Col>
													</Row>
													</React.Fragment>
                          : null}
												</CardBody>
												</Card>
											  {!checkIfEmployee(this.props.userData) ?
												<FormGroup>
													<FormGroup check className="custom-check checkbox">
														<Label check>
															<Input
																type="checkbox"
                                checked={this.props.userData
                                    && this.props.userData.settings
                                    && this.props.userData.settings.is_north_america_unified_account ? true : false}
                                onChange={e => this.handleUpdateUnifiedAccount()}
                                disabled={this.props.isUserSettingsUpdating}
															/> <span>Set to North America Unified Account</span>
														</Label>
													</FormGroup>
												</FormGroup>
                        : null}
												<Card className="mt-5">
													<CardBody>
														<Row>
															<Col xs="9">
																<div className="card-title text-uppercase">
																	Auth Accelerlist on eBay
																</div>
                                {this.props.userData?.settings?.ebay_refresh_token_expire
                                  ? <p>Creds expire: {momentDateToISOFormatConversioniWithFormat(this.props.userData?.settings?.ebay_refresh_token_expire * 1000, "L")}</p>
                                  : null
                                }
                                {this.props.userData
                                  && this.props.userData.ebay_auth
                                  && this.props.userData?.settings?.ebay_refresh_token_expire
                                  && moment(this.props.userData?.settings?.ebay_refresh_token_expire * 1000).subtract(15, "days") > moment()
                                  ?	<strong className="text-success"><FaCheck /> Authorized on eBay</strong>
																	:
																		<Button
																			color="success"
																			onClick={() => this.executeAuth()}
																			disabled={this.props.user_auth_processing || this.state.userAuthExecuted}
																		>Authorize on eBay</Button>
																}
															</Col>
														</Row>
													</CardBody>
													{this.props.userData && this.props.userData.ebay_auth ?
														<CardBody className="mt-5">
															<h6 className="card-title text-uppercase">
																eBay/PayPal Settings
															</h6>
															<p>eBay Authorized with:</p>
															<FormGroup>
																<Label>PayPal Email:</Label>
																<Input
																	type="email"
																	value={this.state.ebay_paypal_email}
																	onChange={e => this.updatePaypalEmail(e.target.value)}
																/>
															</FormGroup>
															<Button
																color="success"
																onClick={e => this.savePaypalEmail()}
															>Save PayPal Email</Button>
														</CardBody>
													: null }
													</Card>
											</Col>
											<Col>
											{!checkIfEmployee(this.props.userData) ?
                        <React.Fragment>
                          <UpdatePasswordForm />
                          {this.props.userData ? (
                            <AffiliateForm
                              className="mt-4"
                              userData={this.props.userData}
                              updateUserData={this.props.updateUserData}
                              updateUserSettings={this.props.updateUserSettings}
                            />
                            ) : (null)
                          }
                        </React.Fragment>
                        : null
                      }
											</Col>
									</Row>
								</CardBody>
							</Card>
						</TabPane>
						{!checkIfEmployee(this.props.userData) ?
						<TabPane tabId="2">
							<Address />
						</TabPane>
						: null}
						<TabPane tabId="3">
							<PrinterDefaultsQz />
						</TabPane>
						<TabPane tabId="4">
							<Card style={{overflow: "inherit"}}>
								<CardBody>
									<Nav tabs>
										<NavItem>
											<NavLink
												active={this.state.activeListingChild === '4a'}
											 	onClick={() => this.setState({ activeListingChild: '4a' })}
											>
												Listing Defaults
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												active={this.state.activeListingChild === '4b'}
											 	onClick={() => this.setState({ activeListingChild: '4b' })}
											>
												Rank Alert
											</NavLink>
										</NavItem>
									</Nav>
									<TabContent activeTab={this.state.activeListingChild} className="py-3">
										<TabPane tabId="4a">
											{this.state.activeListingChild === '4a' &&
											<React.Fragment>
												<ListingDefaults
													userError={userError}
													updateUserData={this.props.updateUserData}
													updateUserSettings={this.props.updateUserSettings}
												/>
											</React.Fragment>
											}
										</TabPane>
										<TabPane tabId="4b">
											{this.state.activeListingChild === '4b' &&
											<React.Fragment>
												<p>Below is high sales rank alert you've added on product listing, you can change range values or remove the alert.</p>
												<Listing_Alerts />
												{/* <Row>
													<Col xs={5}>
														<FormGroup>
															<Label>High Sales Rank Alert Threshold</Label>
															<Input type="number" placeholder="Example: 1000" value={this.state.sales_rank_threshold || ""} onChange={e => this.setState({ sales_rank_threshold: e.target.value})} />
															<FormText>Show alert if product sales rank is higher than defined threshold.</FormText>
														</FormGroup>
													</Col>
												</Row>
												<Button onClick={this.saveNewDefaults} color="success" disabled={this.state.saveMessageFlashing}>Save settings</Button>
												<br />
												{ this.state.saveMessageFlashing
													? (
														<label>
															Listing defaults saved
														</label>
														)
													: (null)
												} */}
											</React.Fragment>
											}
										</TabPane>
									</TabContent>

								</CardBody>
							</Card>
						</TabPane>
						<TabPane tabId="5">
							<Card>
								<CardBody>
									<Nav tabs>
										<NavItem>
											<NavLink
												active={this.state.activeChildTab === '5a'}
												onClick={() => { this.toggleChildTabs('5a'); }}
											>
												Create Template
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												active={this.state.activeChildTab === '5b'}
												onClick={() => { this.toggleChildTabs('5b'); }}
											>
												Saved Templates
											</NavLink>
										</NavItem>
									</Nav>
									<TabContent activeTab={this.state.activeChildTab} className="py-3">
										<TabPane tabId='5a'>
                      {this.state.activeChildTab === '5a' &&
                        <React.Fragment>
                          <h4>Amazon MSKU Settings</h4>
                          <SKUSettings
                            onSaveListingDefaults={this.props.saveListingDefaults}
                            onFetchListingDefaults={this.props.fetchListingDefaults}
                            listingDefaults={listingDefaults}
                            UseDefaultCustomSKU={listingDefaults.should_use_custom_sku_template}
                          />
                          {this.props.userData
                              && this.props.userData.is_ebay_enabled
                              && this.props.userData.ebay_auth ?
                                <React.Fragment>
                                  <hr className="my-5" />
                                  <h4>eBay MSKU Settings</h4>
                                  <EbaySKUSettings />
                                </React.Fragment>
                                : null
                          }
                        </React.Fragment>
                      }
										</TabPane>
										<TabPane tabId='5b'>
                      {this.state.activeChildTab === '5b' &&
                        <SKUSettingsSaved
                          onUseTemplate={() => {
                            // use template implementation here
                            this.toggleChildTabs('5a')
                          }}
                          onEditTemplate={() => {
                            // edit template implementation here
                            // show sku template ready to edit including nickname
                            this.toggleChildTabs('5a')
                          }}
                        />
                      }
                      </TabPane>
									</TabContent>
								</CardBody>
							</Card>
						</TabPane>
						{!checkIfEmployee(this.props.userData) ?
						<React.Fragment>
						<TabPane tabId="6">
							<Card>
								<CardBody>
									<Row>
										<Col lg="6">
											<SupplierList />
										</Col>
										<Col lg="6">
											<ScoutList />
										</Col>
									</Row>
								</CardBody>
							</Card>
						</TabPane>
						<TabPane tabId="7">
							<Card>
								<CardBody>
									<CardTitle>Update Billing</CardTitle>
									<UpdateBilling
										invoicesPerPage={10}
									/>
								</CardBody>
							</Card>
						</TabPane>
						<TabPane tabId="8">
							<Card>
								<CardBody>
									<CardTitle>Profit Reprice&trade; Settings</CardTitle>
									<br />
									<p>COMING SOON!</p>
								</CardBody>
							</Card>
						</TabPane>
						<TabPane tabId="9">
							<Card>
								<CardBody>
									<CardTitle>Employee Accounts</CardTitle>
									<EmployeeAccounts />
                </CardBody>
              </Card>
		  			</TabPane>
						<TabPane tabId="10">
							{this.props.userData
									&& this.props.userData.is_ebay_enabled
									&& this.props.userData.ebay_auth ?
                  <EbaySettings
                    userData={this.props.userData}
                    updateUserData={this.props.updateUserData}
                    updateUserSettings={this.props.updateUserSettings}
                    ebay_user_settings={this.props.ebay_user_settings}
                    ebay_user_settings_updating={this.props.ebay_user_settings_updating}
                    ebayUserUpdateSettings={this.props.ebayUserUpdateSettings}
                  /> : null
							}
						</TabPane>
						</React.Fragment>
						: null }
					</TabContent>
				</div>

				</ViewContent>
				{currentAlert ? (
					<SweetAlert
						show={currentAlert !== null}
						title={currentAlert.title}
						text={currentAlert.text}
						confirmButtonColor={"#3085d6"}
						onConfirm={() => closeSettingsAlert()}
					/>
				) : (
					""
				)}
			</div>
		);
	}
}

export default connect(
  state => {
    return {
      mwsAuthValues: state.Auth.get("mwsAuthValues"),
      userData: state.Auth.get("userData"),
      listingDefaults: state.Settings.toJS().listingDefaults,
      currentAlert: state.Settings.get("currentAlert"),
      isUserSettingsUpdating: state.Settings.get("isUserSettingsUpdating"),
			user_auth_processing: state.Ebay.get("user_auth_processing"),
      ebay_user_settings: state.Ebay.get("ebay_user_settings"),
      ebay_user_settings_updating: state.Ebay.get("ebay_user_settings_updating"),
      user_spapi_auth_start_loading: state.Auth.get("user_spapi_auth_start_loading"),
      user_spapi_auth_lwa_loading: state.Auth.get("user_spapi_auth_lwa_loading"),
    };
  },
  {
    fetchListingDefaults,
    closeSettingsAlert,
    openMwsAuthorizeModal,
    saveListingDefaults,
		userError,
		updateUserDataSettings,
		getUser,
		updateUserData,
		updateUserSettings,
    ebayUserAuth,
    ebayUserUpdateSettings,
    userSPAPIAuthStart,
    userSPAPIAuthLWA,
    userPageviewCapture,
  }
)(Settings);
