// CheckoutForm.js
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
//import { withRouter } from "react-router";
import {
  injectStripe,
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement
} from "react-stripe-elements";
import TermsConditions from "../../../shared/components/TermsConditions";
import { Alert } from "reactstrap";
import { digitСonversion } from "../../../helpers/utility";

import authAction from "../../../redux/auth/actions";

import { logoColorMd } from "../../../assets/images";
import "./style.css";
import { FlagIcon } from 'react-flag-kit';
import { loadReCaptcha } from 'react-recaptcha-v3'
import { ReCaptcha } from 'react-recaptcha-v3'
import Select from 'react-select';
import {
  FaEye,
  FaEyeSlash,
  FaUser,
  FaLock,
  FaBuildingO,
  FaMobile,
  FaTicket,
  FaEnvelopeO,
  FaMapMarker
 } from 'react-icons/lib/fa';
import moment from "moment";
import MaskedInput from 'react-text-mask';
import VerifyPhoneComponent from "../../../shared/components/VerifyPhoneComponent";
import { Button } from 'reactstrap';

const {
  signup,
  userSMSConfirmationDirectSend,
  userSMSConfirmDirect,
} = authAction;

const plansSelectOptions = [
  {
    value: "AL",
    label: "Product Listing + Research + Accounting - $39.99",
    price: "39.99",
    ebayPrice: "0",
    plan_name: "AL",
    ebay: 0,
  },
  /*
  {
    value: "AL",
    label: "Product Listing + Research + Accounting + eBay Cross Listing - $54/mo",
    price: "54",
    ebayPrice: "20",
    plan_name: "AL+EBAY",
    ebay: 20,
  },
  */
  {
    value: "0-2000",
    label: "Product Listing + Research + Accounting + eBay Cross Listing + Repricing - $53.99/mo",
    price: "53.99",
    ebayPrice: "20",
    plan_name: "AL+EBAY+REPRICER",
    ebay: 20,
  },
]

const labelFlag = (data) => (
	<span><FlagIcon code={data.value} /> ({data.label})</span>
);

class CheckoutForm extends React.Component {
  state = {
    username: "",
    password: "",
    verifyPassword: "",
    email: "",
    businessName: "",
    acceptTnc: false,
    registerWithoutAccept: false,
    registerOnGoing: false,
    errorUsername: "",
    errorPassword: "",
    errorEmail: "",
    errorVerifyPassword: "",
    errorCreditCard: "",
    formValid: null,
    creditInfoEmpty: true,
    professional_account: false,
		marketplace_listing_plan: [],
    captcha_token: "",
    chosen_plan: null,
    chosen_plan_price: "",
		errorPlan: "",
		ebayActivate: false,
    passwordHidden: true,
    coupon_code: "",
    coupon_code_finished: false,
    professinalAccountConfirm: false,
    marketplaceConfirm: false,
    ebay_price: 0,
    selected_plan_box: "",
    selected_country: null,
    phone: "",
    sms_code_show: false,
    sms_code: "",
    address: "",
    errorAddress: "",
  };

  componentDidMount() {
		try{
			loadReCaptcha('6LcINr8UAAAAAAemTlMsXwMqKIUBTGQKsq_5BLZx');
		} catch (error) {
			console.error('Captcha ERROR', error);
		}
    if(this.props.location && this.props.location.search){
      if(this.props.location.search.includes('email')){
        let email = this.props.location.search.split('email');
        email = email[email.length - 1].split('&')[0];
        email = email.split('=');
        email = email[email.length - 1].trim();
        this.setState({ email: email });
      }
    }
    if (this.props.location.state && this.props.location.state.userEmail) {
      this.setState({
        email: this.props.location.state.userEmail
      });
		}
		if (this.props.location.state && this.props.location.state.landingPlanSelected) {
			const selected_plan = plansSelectOptions.find(item => item.plan_name === this.props.location.state.landingPlanSelected);
      this.setState({
				chosen_plan: selected_plan,
				chosen_plan_price: selected_plan.price,
        selected_plan_box: selected_plan.plan_name,
      });
		}
		if (this.props.location.state && this.props.location.state.includeEbay) {
			this.setState({ ebayActivate: this.props.location.state.includeEbay });
		}
  }

	toggleeBayActivate = (e) => {
		this.setState({ ebayActivate: !this.state.ebayActivate});
	}

  validateUsername = username => {
    if (username === "") {
      this.setState({ errorUsername: "Username is required" });
      return false;
    } else if (username.match(/[A-Z\s]+/)){
      this.setState({ errorUsername: "Username can't contain space or uppercase" });
      return false;
    } else {
      this.setState({ errorUsername: "" });
      return true;
    }
  };

  validatePassword = (password, verifyPassword) => {
    if (password === "") {
      this.setState({ errorPassword: "Password is required" });
      return false;
    } else if (verifyPassword === "") {
      this.setState({ errorVerifyPassword: "Confirm password is required" });
      return false;
    } else {
      this.setState({ errorPassword: "", errorVerifyPassword: "" });
      return true;
    }
  };

  validateVerifyPassword = (password, verifyPassword) => {
    if (password !== verifyPassword) {
      this.setState({
        errorPassword: "",
        errorVerifyPassword: "Your passwords are not the same"
      });
      return false;
    } else {
      this.setState({ errorPassword: "", errorVerifyPassword: "" });
      return true;
    }
  };

  validateEmail = email => {
	//const regexEmailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const regexEmailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email === "") {
      this.setState({ errorEmail: "Email is required" });
      return false;
    } else if (!regexEmailValidation.test(String(email).toLowerCase())) {
      this.setState({ errorEmail: "Please enter a valid email address" });
      return false;
    } else {
      this.setState({ errorEmail: "" });
      return true;
    }
  };

  validateCreditNumber = creditNumber => {
    this.setState({ creditInfoEmpty: creditNumber });
    if (creditNumber) {
      this.setState({ errorCreditCard: "Card information is required" });
    } else {
      this.setState({ errorCreditCard: "" });
    }
  };

	validatePlan = (plan) => {
		if (plan) {
      this.setState({ errorPlan: "" });
      return true;
    }
    this.setState({ errorPlan: "Plan need to be selected" });
    return false;
	}

	validateProfessinalAccount = () => {
		if (this.state.professional_account) {
      this.setState({ professinalAccountConfirm: false });
      return true;
    }
    this.setState({ professinalAccountConfirm: true });
    return false;
	}

	validateMarketplace = () => {
    if (this.state.marketplace_listing_plan.length > 0) {
      this.setState({ marketplaceConfirm: false });
      return true;
    }
    this.setState({ marketplaceConfirm: true });
    return false;
	}

  validatePhoneCountry = (country) => {
    if(country?.label){
      this.setState({ phoneCountryConfirm: true });
      return true;
    }
    this.setState({ phoneCountryConfirm: false });
    return false;
  }

  validatePhoneNumber = (phone) => {
    const { selected_country } = this.state;
    this.state.selected_country?.label === '+49'
    if(
      (phone && phone.length === 10 && selected_country?.label !== '+49')
       || (phone && phone.length === 11 && selected_country?.label === '+49')
    ){
      this.setState({ phoneNumberConfirm: true });
      return true;
    }
    this.setState({ phoneNumberConfirm: false });
    return false;
  }

  validateAddress = (address) => {
    if(address){
      this.setState({ errorAddress: "" });
      return true;
    }
    this.setState({ errorAddress: "Address is required" });
    return false;
  }

  validateForm = () => {
    const {
      username,
      password,
      verifyPassword,
      email,
			creditInfoEmpty,
			chosen_plan,
      phone,
      selected_country,
      address,
    } = this.state;

    this.validateUsername(username);
    this.validatePassword(password, verifyPassword);
    if (this.validatePassword(password, verifyPassword)) {
      this.validateVerifyPassword(password, verifyPassword);
    }
    this.validateEmail(email);
    this.validateCreditNumber(creditInfoEmpty);
    this.validatePlan(chosen_plan);
    this.validateProfessinalAccount();
    this.validateMarketplace();
    this.validatePhoneCountry(selected_country);
    this.validatePhoneNumber(phone);
    this.validateAddress(address);
    if (this.validateUsername(username)
      && this.validatePassword(password, verifyPassword)
      && this.validateEmail(email)
      && this.validateVerifyPassword(password, verifyPassword)
      && this.validatePlan(chosen_plan)
      && this.validatePhoneCountry(selected_country)
      && this.validatePhoneNumber(phone)
      && this.validateAddress(address)
    ) {
      this.setState({ formValid: true });
      return true;
    } else {
      this.setState({ formValid: false });
      return false;
    }
  };

  handleSubmit = (e, formType) => {
    const { formValid, coupon_code } = this.state;
    let form_valid = formValid;
    e.preventDefault();

		form_valid = this.validateForm();
		// if(!this.validateForm()){
		// 	return;
		// };

    let registrationPlanId = null;
		if(this.state.chosen_plan){
			registrationPlanId = this.state.chosen_plan.value;
		}

    if (!this.state.acceptTnc) {
      this.setState({ registerWithoutAccept: true });
      return;
    } else {
      this.setState({ registerOnGoing: true, errorCreditCard: "" });
    }

    let spec_trial_days = null;
    if (this.props.location.state && this.props.location.state.cobranded) {
      if(this.props.location.state.cobranded === 'BBB') {
        spec_trial_days = 30;
      }
    }

    if (form_valid) {
      if(window?.tolt_referral){
        window.tolt.signup(this.state.email);
      }

      this.props.stripe
        .createToken({ name: this.state.username })
        .then(response => {
          if (response.token) {
            let authData = {
              username: this.state.username,
              businessName: this.state.businessName,
              email: this.state.email,
              password: this.state.password,
              stripeToken: response.token.id,
              phone: `${this.state.selected_country?.label}${this.state.phone}`,
              registrationPlanId: registrationPlanId,
							captcha_token: this.state.captcha_token,
							ebayActivate: this.state.ebayActivate,
              spec_trial_days: spec_trial_days,
              coupon_code: coupon_code,
              sms_code: this.state.sms_code,
              address: this.state.address,
              affiliate: window?.tolt_referral ? window.tolt_referral : null,
            };

            this.props.signup(authData);
            this.setState({ registerOnGoing: false });
          } else {
            this.setState({
              registerOnGoing: false,
              errorCreditCard: response.error.message
            });
          }
        });
    } else {
      this.setState({ registerOnGoing: false });
    }
  };

  handleInputChange = (inputType, e) => {
    const { username, email, password, verifyPassword } = this.state;
    if(inputType === 'phone') {
      this.setState({ sms_code_show: false });
    }
    let change = {};
    const phoneNoRegex = /^\+{0,1}[0-9]{0,14}$/;
    if (inputType === "phone" && !phoneNoRegex.test(e.target.value)) {
      e.target.value = this.state[inputType] || "";
    } else {
      if (
        inputType === "phone" &&
        e.target.value &&
        e.target.value.indexOf("+") === -1
      ) {
        e.target.value = `+${e.target.value}`;
      }
      change[inputType] = e.target.value;
      this.setState(change);
      if (inputType === "username") {
        this.validateUsername(e.target.value);
        this.setState({ sms_code_show: false });
      } else if (inputType === "email") {
        this.validateEmail(e.target.value);
        this.setState({ sms_code_show: false });
      } else if (inputType === "password") {
        this.validatePassword(e.target.value);
      } else if (inputType == "address") {
        this.validateAddress(e.target.value)
      }
      if (!!username && !!email && !!password && !!verifyPassword) {
        this.setState({ formValid: true });
      }
    }
  };

  toggleAccept = () => {
    this.setState({
      acceptTnc: !this.state.acceptTnc,
      registerWithoutAccept: this.state.acceptTnc
    });
  };

  toggleProfessional = () => {
    this.setState({
      professional_account: !this.state.professional_account
    });
  }

  toggleListingPlan = (marketplace) => {
    let plan = this.state.marketplace_listing_plan;
    if(plan.includes(marketplace)) {
      plan = plan.filter(m => m !== marketplace );
    } else {
      plan.push(marketplace);
    }

    this.setState({
      marketplace_listing_plan: plan
    });
  }

  renderLoader = () => {
    return (
      <div className="loader-container">
        <div className="loader" />
      </div>
    );
	};

	verifyCallback = (recaptchaToken) => {
		this.setState({ captcha_token: recaptchaToken })
  }

  togglePlan = (e) => {
		if(e){
			this.setState({
				chosen_plan: e,
				chosen_plan_price: e.price,
		  });
		} else {
			this.setState({
				chosen_plan: null,
				chosen_plan_price: "",
		  });
		}
  }

  applyCoupon = () => {
    this.setState({ coupon_code_finished: !this.state.coupon_code_finished });
    if(this.state.coupon_code_finished){
      this.setState({ coupon_code: "" });
    }
  }

  toggleAddEbay = () => {
    this.setState({
      ebayActivate: !this.state.ebayActivate,
      ebay_price: !this.state.ebayActivate ? 20 : 0,
    })
  }

  selectPlan = (id) => {
    this.setState({ selected_plan_box: id })
    const plan = plansSelectOptions.find(item => item.plan_name === id);
    this.togglePlan(plan);
    this.setState({
      ebayActivate: plan.ebay ? true : false,
      ebay_price: plan.ebay ? plan.ebay : 0,
    });
  }

  onCountryChange = (value) => {
    this.setState({
      selected_country: value,
      sms_code_show: false,
    })
  }

  sendActivationSMSCode = () => {
    this.setState({sms_code_show: true});
    const data = {
      phone: `${this.state.selected_country?.label}${this.state.phone}`,
      email: this.state.email,
      username: this.state.username,
      marketplace_id: this.state.selected_country?.value === 'GB' ? "A1F83G8C2ARO7P" : null,
    }
    this.props.userSMSConfirmationDirectSend(data);
  }

  executeValidateSMSCode = () => {
    const data = {
      phone: `${this.state.selected_country?.label}${this.state.phone}`,
      email: this.state.email,
      username: this.state.username,
      code: this.state.sms_code,
    }
    this.props.userSMSConfirmDirect(data);
  }

  render() {
    const {
      trial_days,
      internationalization_config,
      signupRequestInProcess
    } = this.props;
    const {
      registerOnGoing,
      errorUsername,
      errorEmail,
      errorPassword,
      errorVerifyPassword,
      errorCreditCard,
      formValid,
      registerWithoutAccept,
      professional_account,
      marketplace_listing_plan,
			chosen_plan_price,
			errorPlan,
      professinalAccountConfirm,
      marketplaceConfirm,
      ebayActivate,
      ebay_price,
      phoneNumberConfirm,
      phoneCountryConfirm,
      errorAddress,
    } = this.state;

    const stripeStyles = {
      base: {
        fontSize: "18px",
        fontWeight: "100",
        fontFamily: '"Poppins", sans-serif',
        padding: "0 20px 0 48px",
        color: "black",
        "::placeholder": {
          color: "#d3d3d3"
        }
      }
    };

    let phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    if(this.state.selected_country !== null && this.state.selected_country.value === "GB") {
      phoneMask = [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    }
    if(this.state.selected_country !== null && this.state.selected_country.value === "DE") {
      phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    }

    return (
      <React.Fragment>
        <fieldset disabled={this.props.signupSMSCodeShow}>
          <form
            className="register"
            id="registrationForm"
            onSubmit={this.handleSubmit.bind(this)}
            name="registrationForm"
          >
            <div className="logo">
              <img src={logoColorMd} alt="logo" className="img-fluid" />
            </div>
            <div className="f-card">
              <div className="register-plans">
                <h2 className="h5 text-center">Select Plan</h2>
                <p className="text-danger text-center"><strong>(You will not be charged until trial period is over.)</strong></p>
                <div className="pricing-selection">
                  <div
                    className={`plan-box ${this.state.selected_plan_box === "AL" && "selected"}`}
                    onClick={() => this.selectPlan("AL")}
                  >
                    <ul className="list-unstyled plan-box-features">
                      <li>Product Listing</li>
                      <li>Research</li>
                      <li>Accounting</li>
                      <li><strong>Mobile App</strong></li>
                      <li><strong>eBay Cross Listing</strong></li>
                    </ul>
                    <h3 className="price">$39.98<small>/MO</small></h3>
                  </div>
                  {/*
                  <div
                    className={`plan-box ${this.state.selected_plan_box === "AL+EBAY" && "selected"}`}
                    onClick={() => this.selectPlan("AL+EBAY")}
                  >
                    <ul className="list-unstyled plan-box-features">
                      <li>Product Listing</li>
                      <li>Research</li>
                      <li>Accounting</li>
                      <li><strong>eBay Cross Listing</strong><br/> <small>(up to 5,000 products)</small></li>
                    </ul>
                    <h3 className="price">$54<small>/MO</small></h3>
                  </div>
                  */}
                  <div
                    className={`plan-box ${this.state.selected_plan_box === "AL+EBAY+REPRICER" && "selected"}`}
                    onClick={() => this.selectPlan("AL+EBAY+REPRICER")}
                  >
                    <ul className="list-unstyled plan-box-features">
                      <li>Product Listing</li>
                      <li>Research</li>
                      <li>Accounting</li>
                      <li><strong>Mobile App</strong></li>
                      <li><strong>eBay Cross Listing</strong></li>
                      <li><strong>Repricing</strong><br/> <small>(reprice up to 2,000 products)</small></li>
                    </ul>
                    <h3 className="price">$63.98<small>/MO</small></h3>
                  </div>
                </div>
                {!!errorPlan && (
                  <div className="f-row-error">
                    <Alert color="warning">{errorPlan}</Alert>
                  </div>
                )}
              </div>
              <hr />
              <div className="inline-row">
                <div className="f-container">
                  <label htmlFor="#">Please pick a username <span className="text-danger">*</span></label>
                  <div className="f-row">
                    <input
                      name="username"
                      placeholder="Username"
                      type="text"
                      value={this.state.username}
                      autoComplete="new-username"
                      onChange={e => {
                        this.handleInputChange("username", e);
                      }}
                    />{" "}
                    <FaUser />
                    {!!errorUsername && (
                      <div className="f-row-error">
                        <Alert color="warning">{errorUsername}</Alert>
                      </div>
                    )}
                  </div>
                  <span className="form-text text-warning">Do not use an email</span>
                </div>
                <div className="f-container">
                  <label htmlFor="#">Please give us your best email <span className="text-danger">*</span></label>
                  <div className="f-row">
                    <input
                      name="email"
                      placeholder="Email Address"
                      type="text"
                      value={this.state.email}
                      onChange={e => this.handleInputChange("email", e)}
                    />{" "}
                    <FaEnvelopeO />
                    {!!errorEmail && (
                      <div className="f-row-error">
                        <Alert color="warning">{errorEmail}</Alert>
                      </div>
                    )}
                  </div>
                  <span className="form-text text-warning">You'll need this for password resets, etc so it's important</span>
                </div>
              </div>
              <div className="inline-row">
                <div className="f-container">
                  <label htmlFor="#">Pick a password you will remember <span className="text-danger">*</span></label>
                  <div className="f-row">
                    <div className="input-group">
                      <input
                        name="password"
                        placeholder="Password"
                        type={this.state.passwordHidden ? "password" : "text"}
                        value={this.state.password}
                        autoComplete="new-password"
                        onChange={e => this.handleInputChange("password", e)}
                      />
                      <div
                        className="input-group-addon input-group-append cursor-pointer"
                        onClick={() => this.setState({ passwordHidden: !this.state.passwordHidden })}
                      >
                        {this.state.passwordHidden ? <FaEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                    <FaLock />
                    {!!errorPassword && (
                      <div className="f-row-error">
                        <Alert color="warning">{errorPassword}</Alert>
                      </div>
                    )}
                  </div>
                </div>
                <div className="f-container">
                  <label htmlFor="#">Let's confirm that password <span className="text-danger">*</span></label>
                  <div className="f-row">
                    <div className="input-group">
                      <input
                        name="confirm-password"
                        placeholder="Confirm Password"
                        type={this.state.passwordHidden ? "password" : "text"}
                        onChange={e =>
                          this.handleInputChange("verifyPassword", e)
                        }
                      />
                      <div
                        className="input-group-addon input-group-append cursor-pointer"
                        onClick={() => this.setState({ passwordHidden: !this.state.passwordHidden })}
                      >
                        {this.state.passwordHidden ? <FaEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                    <FaLock />
                    {!!errorVerifyPassword && (
                      <div className="f-row-error">
                        <Alert color="warning">{errorVerifyPassword}</Alert>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="inline-row cards-n">
                <div className="f-container card-n">
                  <label htmlFor="#">Debit or Credit Card Number <span className="text-danger">*</span></label>
                  <div className="f-row">
                    <CardNumberElement
                      style={stripeStyles}
                      onChange={element => {
                        this.validateCreditNumber(element.empty);
                      }}
                    />
                  </div>
                  <span className="form-text text-warning">You will not be charged during your trial period.</span>
                  {!!errorCreditCard && (
                    <div className="f-row-error">
                      <Alert color="warning">{errorCreditCard}</Alert>
                    </div>
                  )}
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="f-container">
                    <label htmlFor="#">Card Expiration (MM/YY) <span className="text-danger">*</span></label>
                    <div className="f-row without-icon">
                      <CardExpiryElement style={stripeStyles} />
                    </div>
                  </div>
                  <div className="f-container">
                    <label htmlFor="#">CVV/CVV2 <span className="text-danger">*</span></label>
                    <div className="f-row without-icon">
                      <CardCVCElement style={stripeStyles} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="inline-row">
                <div className="f-container">
                  <label htmlFor="#">Business Name</label>
                  <div className="f-row">
                    <input
                      name="businessName"
                      placeholder="Business Name"
                      type="text"
                      value={this.state.businessName}
                      onChange={e =>
                        this.handleInputChange("businessName", e)
                      }
                    />{" "}
                    <FaBuildingO />
                  </div>
                </div>
                <div className="f-container">
                  <label htmlFor="#">
                    Mobile Phone <span className="text-danger">*</span> (we will send you a registration code)
                  </label>
                  <div className="f-row">
                    <div className="input-group">
                      <div
                        className="input-group-prepend"
                        style={!this.state.selected_country ? { border: "1px solid red", marginRight: 0 } : {}}
                        >
                        <Select
                          optionRenderer={labelFlag}
                          valueRenderer={labelFlag}
                          style={{ width: '7.3rem' }}
                          clearable={false}
                          options={[
                            {value: 'US', label: '+1'},
                            {value: 'CA', label: '+1'},
                            {value: 'GB', label: '+44'},
                            {value: 'DE', label: '+49'}
                          ]}
                          value={this.state.selected_country}
                          onChange={this.onCountryChange}
                        />
                      </div>
                      <MaskedInput
                        mask={phoneMask}
                        onChange={e =>
                          this.setState({
                            phone: e.target.value.replace(/\D+/g, ''),
                            sms_code_show: false,
                          })
                        }
                        className="form-control"
                        showMask={true}
                        keepCharPositions={true}
                        value={this.state.phone}
                      />
                      <Button
                        color="primary"
                        onClick={() => this.sendActivationSMSCode()}
                        disabled={
                          !(
                            (
                              this.state.phone
                              && this.state.phone.length === 10
                              && this.state.selected_country?.label !== '+49'
                            )
                              ||
                            (
                              this.state.phone
                                && this.state.selected_country?.label === '+49'
                                && this.state.phone.length === 11
                            )
                           )
                            || !this.state.selected_country?.label
                            || this.state.sms_code_show
                            || !this.state.username
                            || !this.state.email
                        }
                      >SEND ACTIVATION CODE</Button>
                    </div>
                  </div>
                  { phoneNumberConfirm === false || phoneCountryConfirm === false ?
                      <div className="f-row-error">
                      <Alert color="warning">
                        You must enter phone number
                      </Alert>
                      </div>
                      :null
                  }
                </div>
              </div>
              {this.state.sms_code_show &&
                <div className="inline-row">
                  <div className="f-container">
                  </div>
                  <div className="f-container">
                    <label htmlFor="#">Enter activation code</label>
                    <div className="f-row">
                      <div className="input-group">
                        <input
                          onChange={e => this.setState({"sms_code": e.target.value})}
                        />
                        <div className="input-group-append">
                          <Button
                            color="primary"
                            onClick={() => this.executeValidateSMSCode()}
                            disabled={this.props.direct_send_confirmation_sms_working}
                          >CONFIRM ACTIVATION CODE</Button>
                        </div>
                      </div>
                      <FaMobile />
                    </div>
                    { this.props.direct_confirmed_sms_error ?
                        <Alert color="warning">
                          {this.props.direct_confirmed_sms_error}
                        </Alert>
                        :null
                    }
                    { this.props.direct_confirmed_sms_ok ?
                        <Alert color="success">
                          Code accepted
                        </Alert>
                        :null
                    }
                  </div>
                </div>
              }
              <div className="inline-row">
                <div className="f-container">
                  <label htmlFor="#">Your Address <span className="text-danger">*</span></label>
                  <div className="f-row">
                    <input
                      name="address"
                      placeholder="Address"
                      type="text"
                      value={this.state.address}
                      autoComplete="new-address"
                      onChange={e => {
                        this.handleInputChange("address", e);
                      }}
                    />{" "}
                    <FaMapMarker />
                    {!!errorAddress && (
                      <div className="f-row-error">
                        <Alert color="warning">{errorAddress}</Alert>
                      </div>
                    )}
                  </div>
                </div>
                <div className="f-container">
                  <label htmlFor="#">Coupon Code</label>
                  <div className="f-row">
                    <div className="input-group">
                      <input
                        name="couponCode"
                        placeholder="Enter coupon code"
                        type="text"
                        value={this.state.coupon_code}
                        onChange={e => this.setState({ coupon_code: e.target.value })}
                        disabled={this.state.coupon_code_finished}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          onClick={e => this.applyCoupon()}
                        >{this.state.coupon_code_finished ? "Reset": "Apply"}</button>
                      </div>
                    </div>
                    <FaTicket />
                  </div>
                  {this.state.coupon_code_finished ?
                    <Alert color="success">Coupon successfully applied</Alert>
                    : null
                  }
                </div>
              </div>
              <div className="f-container">
                <div className="f-row mt-4">
                  <div className="check">
                    <label className="container-check">
                      I have a Professional Selling Account on Amazon
                      <input
                        type="checkbox"
                        defaultChecked={professional_account ? `checked` : ""}
                        onChange={this.toggleProfessional}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  { professinalAccountConfirm && (
                    <div className="f-row-error">
                    <Alert color="warning">
                      You must confirm that you have Professional Selling Account on Amazon
                    </Alert>
                    </div>
                  )}
                </div>
              </div>
              <div className="f-container">
                <p>I plan on listing on one of the following marketplaces:</p>
                <div className="check d-inline-block">
                  <label className="container-check">
                    <strong>.com</strong> (USA <FlagIcon code="US"  />)
                    <input
                      type="radio"
                      name="markeplace-option"
                      defaultChecked={marketplace_listing_plan.includes("US") ? "checked" : ""}
                      onChange={() => this.toggleListingPlan("US")}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="check d-inline-block ml-5">
                  <label className="container-check">
                    <strong>.ca</strong> (Canada <FlagIcon code="CA"  />)
                    <input
                      type="radio"
                      name="markeplace-option"
                      defaultChecked={marketplace_listing_plan.includes("CA") ? "checked" : ""}
                      onChange={() => this.toggleListingPlan("CA")}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="check d-inline-block ml-5">
                  <label className="container-check">
                    <strong>.uk</strong> (England <FlagIcon code="GB"  />)
                    <input
                      type="radio"
                      name="markeplace-option"
                      defaultChecked={marketplace_listing_plan.includes("GB") ? "checked" : ""}
                      onChange={() => this.toggleListingPlan("GB")}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="check d-inline-block ml-5">
                  <label className="container-check">
                    <strong>.de</strong> (Germany <FlagIcon code="DE"  />)
                    <input
                      type="radio"
                      name="markeplace-option"
                      defaultChecked={marketplace_listing_plan.includes("DE") ? "checked" : ""}
                      onChange={() => this.toggleListingPlan("DE")}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                { marketplaceConfirm && (
                  <div className="f-row-error">
                  <Alert color="warning">
                    You must select marketplace
                  </Alert>
                  </div>
                )}
              </div>
              <div className="f-container">
                <div className="check">
                  <label className="container-check">
                    I accept the Terms &amp; Conditions
                    <input
                      type="checkbox"
                      defaultChecked={this.state.acceptTnc ? `checked` : ""}
                      onChange={this.toggleAccept}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <TermsConditions
                  isOpen={this.state.acceptTnc}
                  scroll
                  scrollHeight="300px"
                />
                {registerWithoutAccept && (
                  <div className="f-row-error">
                  <Alert color="warning">
                    You must accept Terms &amp; Conditions
                  </Alert>
                  </div>
                )}
              </div>
              <button
                className="btn btn-block btn-lg btn-success"
              >
                <strong>
                  {signupRequestInProcess || registerOnGoing
                    ? this.renderLoader()
                    : "REGISTER"}
                </strong>
              </button>
              <ReCaptcha
                  sitekey="6LcINr8UAAAAAAemTlMsXwMqKIUBTGQKsq_5BLZx"
                  action='action_name'
                  verifyCallback={this.verifyCallback}
              />
            </div>
          </form>
        </fieldset>
        <div>
          <div>
            {this.props.signupSMSCodeShow ?
              <div className="my-4">
                <VerifyPhoneComponent
                  username={this.state.username}
                />
              </div>
              : null
            }
            <div className="text-center my-3">
              <span>Already a member?</span>&nbsp;
              <Link className="new-account text-muted text-center" to="/signin">
                <strong>Click to Login</strong>
              </Link>
            </div>
          </div>
          <div className="copyright">
            {moment().format('YYYY')} Copyright <span>AccelerList</span>.
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CheckoutForm.propTypes = {
  signup: PropTypes.func.isRequired,
  plan_price: PropTypes.number.isRequired,
  trial_days: PropTypes.number.isRequired,
  billing_cycle_length: PropTypes.string.isRequired
};

export default withRouter(
  connect(
    state => ({
      ...state.Auth.toJS(),
      internationalization_config: state.Auth.get("internationalization_config"),
      direct_send_confirmation_sms_working: state.Auth.get("direct_send_confirmation_sms_working"),
      direct_send_confirmation_sms_ok: state.Auth.get("direct_send_confirmation_sms_ok"),
      direct_confirmed_sms_ok_error: state.Auth.get("direct_confirmed_sms_ok_error"),
      direct_confirmed_sms_ok: state.Auth.get("direct_confirmed_sms_ok"),
    }),
    {
      signup,
      userSMSConfirmationDirectSend,
      userSMSConfirmDirect,
    }
  )(injectStripe(CheckoutForm))
);
