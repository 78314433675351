import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { digitСonversion } from "../../../../helpers/utility";
import { Button } from "reactstrap";
import "../../style.css";
import sbybActions from '../../../../redux/sbyb/actions'
import batchActions from '../../../../redux/batch/actions'
import { openInNewTabExtended } from "../../../../helpers/utility";
import moment from "moment";
import styled, { keyframes } from "styled-components";
import FlipInX from "react-animations/lib/flip-in-x";

const FlipInXAnimation = keyframes`${FlipInX}`;
const FlipInXDiv = styled.div`
  animation: 1s ${FlipInXAnimation};
`;

const { searchSbybData } = sbybActions;
const { sbybUserTracking } = batchActions;

/**
 * Inventory table display
 */
class InventoryTableSBYB extends Component {
	state = {
		loading: [],
		sbybChecked: []
	}

	checkSBYB = (asin, price) => {
		let loading = this.state.loading;
		loading[asin] = true;

		this.setState({
			loading: loading
		})

		this.props.searchSbybData({"isbn": asin, "price": price})

		setTimeout(() => {
			let sbybChecked = this.state.sbybChecked;
			sbybChecked.push(asin);
			loading[asin] = true;

			this.setState({
				sbybChecked: sbybChecked,
				loading: loading
			})
		}, 1500);
	}

	getNextPage = (state) => {
		this.props.fetchInventoryData(parseInt(state.page, 10) + 1, state.pageSize, state.sorted);
	}

	sbybButtonOpen = (listingData, price) => {
		openInNewTabExtended(listingData.link, 'sbyb');
		let tracking_data = {
			sbyb_price: listingData.price.toString(),
			amazon_buy_box_price: price.toString(),
			asin: listingData.isbn,
			amazon_fees: listingData.fees,
		}
		this.props.sbybUserTracking(tracking_data);
	}

	generateTableColums = () => {
		let tableColumns = [
			{
				id: "item_name",
				accessor: "item_name",
				Header: "Product",
				headerClassName: "text-left",
				minWidth: 300,
				style: { alignSelf: "center" },
				Cell: props => (
					<div>
						<div className="text-truncate" style={{ minWidth: 250 }}>{props.original.item_name}</div>
						<div>{`ASIN: ${props.original.asin1}`}</div>
					</div>
				),
			},
			{
				id: "seller_sku",
				Header: "SKU",
				accessor: "seller_sku",
				minWidth: 200,
				headerClassName: "text-left",
				style: { alignSelf: "center" }
			},
			{
				id: "quantity",
				Header: "Qty",
				accessor: "quantity",
				maxWidth: 55,
				className: "text-center",
				style: { alignSelf: "center" }
			},
			{
				id: "price",
				Header: "List Price",
				accessor: "price",
				maxWidth: 90,
				headerClassName: "text-right",
				className: "text-right",
				style: { alignSelf: "center" },
				Cell: props => (
					<div>{digitСonversion(props.value, "currency",
					this.props.internationalization_config.currency_code)}</div>
				)
			},
			{
				id: "buy_cost",
				Header: "Orig. Buy Cost",
				accessor: "buy_cost",
				maxWidth: 125,
				headerClassName: "text-right",
				className: "text-right",
				style: { alignSelf: "center" },
				Cell: props => (
					<div>{digitСonversion(props.value, "currency",
					this.props.internationalization_config.currency_code)}</div>
				)
			},
			{
				id: "open_date",
				Header: "Listing Age Days",
				accessor: "open_date",
				minWidth: 150,
				headerClassName: "text-right",
				className: "text-right",
				Cell: props => (

          console.log(
            'aaaaaaaaaaaaaaaaaaaaa',
            props.original.seller_sku,
            digitСonversion(
              moment().diff(moment(props.original.open_date), 'days'),
              'decimal'
            ),
            props.original.open_date,
            moment(props.original.open_date),
            moment()
          ),

					<div>
						{
							digitСonversion(
								moment().diff(moment(props.original.open_date), 'days'),
								'decimal'
							)
						}
					</div>
				)
			},
			{
				id: "check_sbyb",
				Header: "Check SBYB",
				headerClassName: "text-left",
				style: { overflow: "visible" },
				sortable: false,
				minWidth: 150,
				Cell: props => (
					this.props.sbyb_item_data[props.original.asin1] ?
					<FlipInXDiv
						style={this.state.sbybChecked.includes(props.original.asin1) ?
						{ animation: "none" }	: null}
					>
						<Button className="btn-sbyb-price text-success">
							{this.props.sbyb_item_data[props.original.asin1] ?
									<React.Fragment>
										${this.props.sbyb_item_data[props.original.asin1].price.toFixed(2)}
									</React.Fragment>
									: ""
							}
						</Button>
					</FlipInXDiv>
					:
					<Button
						className="btn-check-sbyb"
						onClick={() => this.checkSBYB(props.original.asin1, props.original.price)}
						>
							{this.state.loading[props.original.asin1] ?
							<span className="icon icon-reload fa-spin" />
							:
							<span className="text">Check SBYB</span>
							}
					</Button>
				)
			},
			{
				id: "add_to_card",
				Header: "Add to Cart",
				className: "text-center",
				style: { overflow: "visible" },
				sortable: false,
				Cell: props => (
					this.props.sbyb_item_data[props.original.asin1] && this.props.sbyb_item_data[props.original.asin1].price > 0 ?
					<Button
						className="btn-add"
						onClick={(e) => this.sbybButtonOpen(
							this.props.sbyb_item_data[props.original.asin1],
							props.original.price
						)}
						color="success"
					>
							Add to Cart
					</Button>
					: null

				)
			},
		];

		return tableColumns;
	}

	render() {
		const { inventoryItems } = this.props;


		return (
			<React.Fragment>
				{inventoryItems ? (
					<ReactTable
						data={inventoryItems.results} // should default to []
						pages={inventoryItems.pages}
						columns={this.generateTableColums()}
						defaultPageSize={10}
						loading={this.props.loading}
						manual // informs React Table that you'll be handling sorting and pagination server-side
						onFetchData={(state, instance) => { this.getNextPage(state) }}
						className="table-sbyb"
						minRows={0}
					/>
				) : (
					""
				)}
			</React.Fragment>
		);
	}
}
InventoryTableSBYB.propTypes = {
	inventoryItems: PropTypes.object,
	fetchInventoryData: PropTypes.func,
	loading: PropTypes.bool.isRequired
};

export default connect(
	state => ({
		internationalization_config: state.Auth.get("internationalization_config"),
		userData: state.Auth.get("userData"),
		sbyb_item_data: state.Sbyb.get("sbyb_item_data"),
	}),
	{
		searchSbybData,
		sbybUserTracking,
	}
)(InventoryTableSBYB);
